@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Define custom CSS variables */
:root {
  /* Primary colors : light theme */
  --primary-active: #3253d1;
  --primary-hover: #2d4bbd;
  --primary-disabled: #bbc9ff;
  --primary-bg: #ffffff;

  /* Secondary colors : dark theme */
  --secondary-active: #3253d1;
  --secondary-hover: #2d4bbd;
  --secondary-disabled: #bbc9ff;
  --secondary-bg: #0f172a;

  /* Title text */
  --title-size: 1.5rem;
  --title-line-height: 2rem;
  --title-weight: 700;
  --title-primary-color: black;
  --title-secondary-color: white;

  /* Subtitle text */
  --subtitle-size: 1rem;
  --subtitle-line-height: 1.5rem;
  --subtitle-weight: 400;
  --subtitle-primary-color: black;
  --subtitle-secondary-color: white;

  /* Paragraph text */
  --paragraph-size: 0.75rem;
  --paragraph-line-height: 1rem;
  --paragraph-weight: 400;
  --paragraph-primary-color: black;
  --paragraph-secondary-color: white;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .flex-center {
    @apply flex justify-center items-center;
  }

  .text-title {
    @apply font-roboto font-[var(--title-weight)] text-[length:var(--title-size)] leading-[var(--title-line-height)] text-[color:var(--title-primary-color)] dark:text-[var(--title-secondary-color)];
  }

  .text-subtitle {
    @apply font-roboto font-[var(--subtitle-weight)] text-[length:var(--subtitle-size)] leading-[var(--subtitle-line-height)] text-[color:var(--subtitle-primary-color)] dark:text-[var(--subtitle-secondary-color)];
  }

  .text-paragraph {
    @apply font-roboto font-[var(--paragraph-weight)] text-[length:var(--paragraph-size)] leading-[var(--paragraph-line-height)] text-[color:var(--paragraph-primary-color)] dark:text-[var(--paragraph-secondary-color)];
  }
}
